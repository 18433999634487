import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'images',
  name: 'Images',
  component: () => import('@/views/dashboard/images/root.vue').then((m) => m.default),
  redirect: () => ({ name: 'images/snapshots' }),
  children: [
    {
      path: 'snapshots',
      name: 'images/snapshots',
      component: () => import('@/views/dashboard/images/snapshots.vue').then((m) => m.default),
    },
    {
      path: 'backups',
      name: 'images/backups',
      component: () => import('@/views/dashboard/images/backups.vue').then((m) => m.default),
    },
    {
      meta: { feature: 'custom_images' },
      path: 'custom',
      name: 'images/custom',
      component: () => import('@/views/dashboard/images/custom.vue').then((m) => m.default),
    },
  ],
} satisfies RouteRecordRaw;
