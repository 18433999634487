import '@/shared/css/main.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';
import '@/request';

import { VueQueryPlugin } from '@tanstack/vue-query';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import Root from '@/app/root.vue';
import { router } from '@/router';

export const app = createApp(Root);
const pinia = createPinia();

app.use(pinia);
app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true,
});
app.use(router);

console.log('init app');
